import 'gridstack/dist/gridstack';
import 'gridstack/dist/gridstack.min.css';

declare const window: any;

export class GridStackLayout {

    grid: any | undefined;
    options: any | undefined;
    context: any | undefined;

    constructor() {
        this.grid = null;
        this.options = null;        
    }

    layout(ref: any, context: any) {
        this.options = context.options;
        this.context = context;
        let gridOptions = {
            float: this.options.floating || true,
            disableDrag: !this.options.isEditable,
            disableResize: !this.options.isEditable,
            draggable: {
                handle: this.options.draggableHandle,
            },
            resizable: {
                handles: 'se, sw'
            },
            cellHeight: '7em',
            acceptWidgets: true
        };

        document.querySelector('.mud-dialog-container')?.children[1].classList.add('composite-dialog');

        this.grid = window.GridStack.init(gridOptions, ref);
        this.grid.on('change', (_event: any, items: any) => this.onChange(items));
    }

    appendElement(_ref: any, id: string) {        
        let element = document.getElementById(id);
        this.grid.makeWidget(element);
        this.grid.commit();
    }

    removeWidget(id: string) {       
        let element = document.getElementById(id);
        this.grid.removeWidget(element);
        this.grid.commit();
    }            

    onChange(items: any) {
        let compositeHeight = 0;
        let compositeId = document.querySelector('div[composite-id]')?.getAttribute('composite-id');
        if (compositeId != null)          
            compositeHeight = Number(document.querySelectorAll('.grid-stack')[0].getAttribute('gs-current-row'));  
        
        let layouts: any = [];
        items.forEach((i: any) => layouts.push({ 'id': i.el.id, 'w': i.w, 'h': i.h, 'x': i.x, 'y': i.y }));
        return this.context.onLayoutChangeCaller
            .invokeMethodAsync(this.context.onLayoutChangeFn, layouts, compositeId, compositeHeight == 0 ? 4 : compositeHeight)
            .then((r: any) => console.log(r));
    }
}
