import { GridStackLayout } from './GridStackLayout';

export function Load() {

    (window as any).layoutGridStackByRef = function (ref: any, context: any) {
        if (ref) {
            let gridStack = (window as any).ItemsManager.get(ref);
            if (!gridStack) {
                gridStack = new GridStackLayout();
                (window as any).ItemsManager.add(ref, gridStack);
            }
            gridStack.layout(ref, context);
        }
    };

    (window as any).appendElementGridStackByRef = function (ref: any, id: string) {
        if (ref) {
            let gridStack = (window as any).ItemsManager.get(ref);
            gridStack.appendElement(ref, id);
        }
    };

    (window as any).updateGridStackByRef = function (ref: any, id: any, position: any) {
        if (ref) {
            let gridStack = (window as any).ItemsManager.get(ref);
            gridStack.update(ref, id, position);
        }
    };

    (window as any).removeElementGridStackByRef = function (ref: any, id: any) {
        if (ref) {
            let gridStack = (window as any).ItemsManager.get(ref);
            gridStack.removeWidget(id);
        }
    };    

    (window as any).scrollToElementId = (elementId: any) => {
        console.info('scrolling to element', elementId);
        let element = document.getElementById(elementId);
        if (!element) {
            console.warn('element was not found', elementId);
            return false;
        }
        element.scrollIntoView();
        return true;
    }
}

